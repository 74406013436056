import React from "react"

import styles from "../styles/main.module.css"

const HeaderTitle = () => {
  return (
    <div>
      <h1><b>a14z</b></h1>
      <p>Hey, welcome to a14z's blog v1.1! Partly inspired by a friend of mine, I'm setting this up to write ideas, reflect, (vent), and maybe even post projects. In the future I will add a portfolio as well. I feel that I have made a lot of misplays in my life and this is part of a broader effort to share my experiences and search for a brighter outlook for the future. </p>
      <p>I'm currently be a December 2022 Junior at MIT, working at <a href="https://www.astranis.com/" target="_blank" rel="noreferrer">Astranis</a>.</p>
      <p>I'm interested in machine learning as an avenue to understand cognition for which I am doing a representation-learning related project that I hope to publish at ICLR. I am also interested in systems and optimization.</p>
        <hr className={styles.top_seperator} />
    </div>
  );
}

const ContactMeLinks = () => {
  return (
    <div className={styles.contact_me_div}>
      <a className={styles.contact_me_link} href="mailto:adrianoh@mit.edu" target="_blank" rel="noreferrer">
        <img className={styles.contact_me_img} alt="" src="https://cdn.jsdelivr.net/npm/simple-icons@latest/icons/gmail.svg" />
      </a>
      <a className={styles.contact_me_link} href="https://github.com/4gatepylon" target="_blank" rel="noreferrer">
        <img className={styles.contact_me_img} alt="" src="https://cdn.jsdelivr.net/npm/simple-icons@latest/icons/github.svg" />
      </a>
      <a className={styles.contact_me_link} href="https://twitter.com/Adriano34554795" target="_blank" rel="noreferrer">
        <img className={styles.contact_me_img} alt="" src="https://cdn.jsdelivr.net/npm/simple-icons@latest/icons/twitter.svg" />
      </a>
    </div>
  );
}

const Posts = () => {
  const reflectionsNames = [
    ["/reflections/2022-06-01", "Learning Things Deeply Enough", "1 June 2022"],
    ["/reflections/2022-05-31", "Texas Method", "31 May 2022"],
    ["/reflections/2022-05-30", "Things I Learned Today", "30 May 2022"],
    ["/reflections/2022-05-29", "My First Reflection", "29 May 2022"],
  ]

  // should be in format [location, displayed text, date]
  // should add at the top so that they will be in chronological order
  const postsNames = [
    ["/posts/zen-and-the-art", "Zen and The Art of Motorcycle Maintenance", "4 May 2021"],
    ["/posts/origami-macrame", "Made some Origami and Macrame", "14 March 2021"],
    ["/posts/deep-work-digital-minimalism", "Deep Work (and Digital Minimalism)", "16 November 2020"],
    ["/posts/socrates-zen", "Was Socrates a Zen Master?", "13 September 2020"],
  ]

  const rantsNames = [
    ["/posts/the-martian", "The Martian", "Unknown Spring 2021"],
    ["/posts/el-zorro", "El Zorro", "2 April 2021"],
    ["/posts/hume-rant", "A Hume Rant", "13 March 2021"],
    ["/posts/the-importance-of-being-earnest", "The Importance of Being Earnest", "28 Febuary 2021"],
    ["/posts/tartar-steppe", "The Tartar Steppe + Rant", "11 Febuary 2021"],
    ["/posts/antifragile", "Antifragile", "31 January 2021"],
    ["/posts/time-to-build", "Time To Build", "31 January 2021"],
    ["/posts/books-december-2020", "Books December 2020" , "2 January 2021"],
    ["/posts/godel-escher-bach", "Gödel Escher Bach", "1 January 2020"],
    ["/posts/zero-to-one", "Zero To One", "27 December 2020"],
    ["/posts/skin-in-the-game", "Skin In The Game", "29 October 2020"],
    ["/posts/epictetus-discourse-chaps-9-11", "Epictetus' Discourses", "26 October 2020"],
    ["/posts/the-black-swan", "The Black Swan", "25 October 2020"],
    ["/posts/population-ethics", "Population Ethics Sucks", "24 October 2020"],
    ["/posts/meditation-experience", "My Brief Excursion Into Meditation", "17 October 2020"],
    ["/posts/books-september-2020", "Books September 2020", "19 September 2020"],
    ["/posts/hello-world", "Hello World", "5 September 2020"],
  ]

  const creationsNames = [
    ["/miscellaneous/boxer", "Boxer (Broken after a React Update in Late 2020)", "Unknown Fall 2021"],
    ["/miscellaneous/clicker", "Clicker", "Unknown Fall 2021"],
    ["/miscellaneous/dragger", "Dragger", "Unknown Fall 2021"],
  ]

  return (
    <div>
      <h1>
        Reflections
      </h1>
      <ul>
      {reflectionsNames.map(reflectionParams => (
          <li>
            <p>
              <a href={reflectionParams[0]}>{reflectionParams[1]}</a> {reflectionParams[2]}
            </p>
          </li>
        ))}
      </ul>
      <h1>
        Posts
      </h1>
      <ul>
        {postsNames.map(postParams => (
          <li>
            <p>
              <a href={postParams[0]}>{postParams[1]}</a> {postParams[2]}
            </p>
          </li>
        ))}
      </ul>
      <h1>
        Rants from 2020/2021
      </h1>
      <ul>
      {rantsNames.map(rantParams => (
          <li>
            <p>
              <a href={rantParams[0]}>{rantParams[1]}</a> {rantParams[2]}
            </p>
          </li>
        ))}
      </ul>
      <h1>
        Creations from 2020/2021
      </h1>
      <ul>
      {creationsNames.map(creationParams => (
          <li>
            <p>
              <a href={creationParams[0]}>{creationParams[1]}</a> {creationParams[2]}
            </p>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default function Home() {
  return (
    <div>
      <HeaderTitle />
      <Posts />
      <ContactMeLinks />
    </div>
    );
}
